import React from 'react';
import img1 from '../assets/pic.svg'
import img2 from '../assets/ime.png'
const Step1 = ({ amount, getamount, use, getuse, validationError }) => {

  return (
    <>
      <div className="container">
        <div className="row flex-d-col ">
          <div className="col-lg-6  col-12 text-start mt-lg-0 mt-4">
            <div>
              <img src={img1} alt="" />
            </div>
            <h3 className="font_28 font_600 mt-2 font-Bold-Ivy ">Need Financing?</h3>
            <p className="font_16 mt-3 font-DM-Sans color_gray">

            </p>

            <div className="row">
              <div className="col-lg-8 col-12 Resp_16  mt-3 mb-3">
                <p className="font_16 mt-4 mb-0 font-DM-Sans color_gray">
                  How much are you looking for?
                </p>
                <div className="input-group">
                  <span
                    className="input-group-text input_field  font_24 font_700 font-Bold-Ivy"
                    style={{ color: "#009934" }}
                    id="basic-addon1"
                  >
                    $
                  </span>
                  <input
                    type="text"
                    className="form-control input_field Resp_16 font_weight_5 ps-0"
                    value={amount}
                    onChange={getamount}

                  />
                </div>
                <div className="mb-3">
                  <label className="font_16 mt-4 mb-0 font-DM-Sans color_gray">
                    Use of funds
                  </label>
                  <select onChange={getuse} name="use_of_funds" className="form-select form-select-lg mb-3 select_field Resp_16 Calibre_regular" aria-label=".form-select-lg example">
                    <option value="" selected="">Select</option>
                    <option value="Working capital">Working capital</option>
                    <option value="Expansion">Expansion</option>
                    <option value="Equipment purchase">Equipment purchase</option>
                    <option value="Cover payroll">Cover payroll</option>
                    <option value="Inventory">Inventory</option>
                    <option value="Consolidation">Consolidation</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                {validationError &&
                  <div className='text-center mt-3' style={{ color: 'red' }}>
                    {validationError}
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="col-lg-6  col-12 d-flex justify-content-center align-items-center text-end position-relative z-3">
            <img src={img2} className="w-75" alt="" />
          </div>
        </div>

      </div>
    </>
  );
}

export default Step1;
