import React, { useState } from 'react';
import Navbar from './Navbar';
import line from '../assets/img13.svg'
import img from '../assets/pic12.svg'
import { useNavigate } from 'react-router-dom';
import { FileUploader } from "react-drag-drop-files";
import axios from 'axios';
import { Alert } from 'reactstrap';
import ButtonLoader from './ButtonLoader';
const ApplicationStep3 = () => {
    const fileTypes = ["PDF", "DOC", "DOCX", "JPG", "PNG", "GIF"];
    const [files, setFiles] = useState([]);
    const [isRequire, setRequire] = useState(false);

    const handleChange = (newFiles) => {
        if (Array.isArray(newFiles)) {
            setFiles([...files, ...newFiles]);
        } else {
            setFiles([...files, newFiles]);
        }
    };

    const handleRemove = (removedFile, event) => {
        event.preventDefault();
        const updatedFiles = files.filter(file => file !== removedFile);
        setFiles(updatedFiles);
    };

    const navigate = useNavigate()
    // ---------GET--DATA---IN---LOCALSTORAGE------
    const storeApplicationThree = localStorage.getItem('applicationThree');
    const parsedstoreApplicationThree = storeApplicationThree ? JSON.parse(storeApplicationThree) : null;
    const stepCapitalLoan = parsedstoreApplicationThree ? parsedstoreApplicationThree.working_capital_loan : '';
    const stepBalance = parsedstoreApplicationThree ? parsedstoreApplicationThree.open_balance_amount : '';
    const stepUseFunds = parsedstoreApplicationThree ? parsedstoreApplicationThree.use_of_funds : '';
    const [capitalLoan, setCapitalLoan] = useState(stepCapitalLoan);
    const [balance, setBalance] = useState(stepBalance);
    const [useFunds, setUseFunds] = useState(stepUseFunds);
    const [sendEmail, setSendEmail] = useState('');


    const [validationError, setValidationError] = useState(null);
    const stepsdata = JSON.parse(localStorage.getItem('stepFourData'))
    const id = JSON.parse(localStorage.getItem('id'))
    const Stepsid = JSON.parse(localStorage.getItem('id'))
    const handleCheckboxChange = (e) => {
        const checked = e.target.checked;
        setSendEmail(checked ? '1' : '0');
    };
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        if ((capitalLoan !== '') && (useFunds.length > 0)) {
            if (capitalLoan !== 'No' && balance === '') {
                setValidationError(<Alert color="danger">
                    please complete the Open Balance Amount detail'
                </Alert>); // Show validation error if credit score is not selected for 'Under 15,000' revenue
                return;
            }
            let data = {
                "id": Stepsid,
                "use_of_funds": useFunds,
                "send_email_for_document": sendEmail,
                "working_capital_loan": capitalLoan,
                "open_balance_amount": balance,
            };
            try {
                const response = await axios.post('https://lohnz.codingsolution24.com/api/create-step-one', data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
                localStorage.removeItem('applicationOne')
                localStorage.removeItem('applicationTwo')
                localStorage.removeItem('stepOne')
                localStorage.removeItem('stepTwo')
                localStorage.removeItem('stepThree')
                localStorage.removeItem('stepFourData')

                navigate('/thanks1');

            } catch (error) {
                console.log(error);
            }
            setValidationError(null)
        }
        else {
            setValidationError(<div className="alert alert-danger text-start" role="alert">
                Please complete the all detail
            </div>)
        }

        // --UPLOAD---STATEMENT------

        if ((capitalLoan != '') && (useFunds.length > 0)) {

            let uploadData = new FormData();
            files.forEach((file) => {
                uploadData.append('statements[]', file);
            });
            uploadData.append('business_id', id);


            let uploadAPI = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://lohnz.codingsolution24.com/api/upload-statements',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
                data: uploadData
            };


            axios.request(uploadAPI)
                .then((response) => {
                    console.log(JSON.stringify(response.data,));

                })
                .catch((error) => {
                    console.log(error);
                });
            setValidationError(null)
        }
        else {
            setValidationError(<div className="alert alert-danger text-start" role="alert">
                please complete the all detail
            </div>)
        }
        localStorage.clear();
    }

    return (

        <div
            className="container-fluid p-0 position-relative "
            style={{ backgroundColor: "#EFF2ED" }}
        >
            <img src={line} className="position-absolute iner_img" alt="Image NOt Found" />
            {/* -------Navbar----------- */}
            <div
                className="container-fluid bg_color position-relative"
                style={{ zIndex: 3 }}
            >
                <div className="container">
                    <Navbar display='none' ms='auto' dNone='none' />
                </div>
            </div>
            {/* ---------main-sec-1----- */}
            <div className="container pt-5">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-8 col-12 mt-3">

                        <p className=" font_15 previous cursor-pointer" onClick={() => navigate(-1)}>
                            <span>
                                <i className="fa-solid fa-arrow-left  me-2" />
                            </span>
                            Back
                        </p>

                        <br className='d-lg-block d-md-block d-none' />    <br className='d-lg-block d-md-block d-none' />
                        <div className="tab">
                            <ul className="d-block nav nav-pills">
                                <div className="nav-item font_15 mt-5 d-flex">
                                    <div>
                                        <img src={img} alt="" />
                                    </div>
                                    <div className="flex-column d-flex justify-content-between ms-4">
                                        <p className="mb-0">Business Information</p>
                                        <p className="mb-0">Owner information</p>
                                        <p className="mb-0">Capital information</p>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-10 col-12 application-fieled  mt-lg-0 mt-md-0  mt-4">
                        <h3 className="Satoshi_fonts mb-3 font_32 font_700 font-Bold-Ivy text-center">
                            Tell us about your Capital
                        </h3>
                        <p
                            style={{ color: "#525252" }}
                            className="DM_fonts font_15 text-center"
                        >
                            Your business complete information will help us to make your process
                            fast
                        </p>
                        <form onSubmit={handleSubmit} className="row DM_fonts">
                            <div className="col-lg-6 col-md-6 col-12 mt-5">
                                <label style={{ color: "#525252" }}>Amount Requested</label>
                                <input
                                    className="form-control Satoshi_fonts input_field font_15"
                                    type="text"
                                    value={stepsdata ? stepsdata.amount_requested : ''}

                                    style={{ fontWeight: 500 }}
                                    readOnly=""
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-12  mt-5">
                                <label style={{ color: "#525252" }} htmlFor="dba">
                                    Use Of Funds
                                </label>
                                <input
                                    className="form-control Satoshi_fonts input_field font_15"
                                    type="text"
                                    value={useFunds}
                                    style={{ fontWeight: 500 }}
                                    onChange={(e) => setUseFunds(e.target.value)}

                                />

                            </div>
                            <div className="col-lg-6 col-md-6 col-12  mt-4">
                                <label style={{ color: "#525252" }}>
                                    Do you currently have a working capital loan?
                                </label>
                                <select
                                    className="form-select input_field font_15 Satoshi_fonts form-control"
                                    aria-label="Default select example"
                                    style={{ fontWeight: 500 }}
                                    value={capitalLoan}
                                    onChange={(e) => setCapitalLoan(e.target.value)}
                                >
                                    <option selected="">Select</option>

                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                            <div className={`col-lg-6 col-md-6 col-12 ${capitalLoan == 'No' ? 'd-none' : 'd-block'} mt-4`}>

                                <label style={{ color: "#525252" }} htmlFor="tax">
                                    Open Balance Amount
                                </label>
                                <input
                                    className="form-control input_field"
                                    type="text"
                                    id="tax"
                                    onChange={(e) => setBalance(e.target.value)}

                                    value={balance}
                                />
                            </div>
                            <div className="col-12 mt-4 font_15">


                                <p style={{ color: "#525252" }} htmlFor="tax" className="mb-1">
                                    3 Months Bank Month to Date Statement
                                </p>
                                <div className="  chose_file pt-4 pb-4 px-3" >
                                    <FileUploader handleChange={handleChange} name="file" types={fileTypes} >
                                        {files.length > 0 && (
                                            <div className='row'>
                                                <h3>Uploaded Files:</h3>

                                                {files.map((file, index) => (
                                                    <div className='col-lg-4 col-md-6 col-sm-6 col-12 mt-3'>

                                                        <div className='bg-file-upload p-3 h-100' key={index}>
                                                            {file.name}
                                                            <br />
                                                            <button className='mt-2'
                                                                onClick={(event) => handleRemove(file, event)}>Remove</button>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                        )}
                                    </FileUploader>
                                </div>
                            </div>
                            <div className="mt-4 mb-0">
                                <p style={{ fontWeight: 500 }} className="text-dark">
                                    Don’t have documents with you right now?
                                </p>
                                <label className="step-three font_15 mb-0">
                                    {files.length == 0 ? <input type="checkbox" value={sendEmail} onChange={handleCheckboxChange} required /> :
                                        <input type="checkbox" onChange={handleCheckboxChange} value={sendEmail} />}

                                    <span className="checkmark" />
                                    Send me an email requesting documents
                                </label>

                            </div>
                            {validationError &&
                                <div className='text-center mt-3' style={{ color: 'red' }}>
                                    {validationError}
                                </div>
                            }
                            <div className="text-center mt-4">
                                <button className=" btn-fill w-75 font_15 ms-1 " type="submit">
                                    {isLoading ? <ButtonLoader /> : 'Continue'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div>

            </div>
            {/*footer*/}
            <footer className="container ">
                <p className="font_20 mt-lg-0 mt-4">
                    <i className="fa-solid fa-phone-volume me-2" />
                    Need Help?
                </p>
                <h3 className="font_22 font_700 font-DM-Sans">310-307-1916</h3>
                <hr style={{ border: "1px solid #FFFFFF" }} />
                <div className="text-center pb-2">
                    <p className="m-0">© Copyright 2023 | LOHNZ. All Rights Reserved.</p>
                </div>
            </footer>
        </div>

    );
}

export default ApplicationStep3;
