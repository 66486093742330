import React from 'react';
import img1 from '../assets/pic3.svg'
import img2 from '../assets/pic6.svg'
const Step4 = ({ getPhoneNumber, getBusinessName, getFirstname, validationError, phoneNumber, email, businessName, firstname, getEmail }) => {
  return (
    <>
      <div className="container">
        <div className="row flex-d-col ">
          <div className="col-lg-6 mt-lg-0 mt-4 col-12 text-start">
            <div>
              <img src={img1} alt="" />
            </div>
            <h3 className="font_28 font_600 mt-2 font-Bold-Ivy ">Need Financing?</h3>
            <p className="font_16 mt-3 font-DM-Sans color_gray">
              Please specify your details below.
            </p>
            <div className="row">
              <div className="col-lg-8  col-12 mb-5">
                <div className="row">
                  <div className="col-lg-6  col-12 mb-3">
                    <label className="font_16 mt-4 mb-0 font-DM-Sans color_gray">First Name</label>
                    <input
                      type="text"
                      className="form-control input_field font_16 font_700 green_color font-DM-Sans "
                      id="formGroupExampleInput2"
                      placeholder="Enter Name"
                      value={firstname}
                      onChange={getFirstname}
                    />
                  </div>
                  <div className="col-lg-6  col-12 mb-3">
                    <label className="font_16 mt-4 mb-0 font-DM-Sans color_gray">
                      Business Name
                    </label>
                    <input
                      type="text"
                      className="form-control input_field"
                      id="formGroupExampleInput2"
                      value={businessName}
                      onChange={getBusinessName}
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="font_16 mt-4 mb-0 font-DM-Sans color_gray">Enter Email</label>
                  <input
                    type="email"
                    className="form-control input_field"
                    id="formGroupExampleInput2"
                    value={email}
                    onChange={getEmail}

                  />
                </div>
                <div className="mb-3">
                  <label className="font_16 mt-4 mb-0 font-DM-Sans color_gray">
                    Enter Phone No.
                  </label>
                  <input
                    type="text"
                    className="form-control input_field"
                    id="formGroupExampleInput2"
                    placeholder=""
                    value={phoneNumber}
                    onChange={getPhoneNumber}                                                                                                                                                   
                  />
                </div>
                <div className=" mb-3 ineer-line Qualified">
                  <div>
                    <p className="font_12 font-DM-Sans px-3">
                      By clicking “Get Qualified ,Submit or Funding Estimate”, you (i)
                      consent to receiving telemarketing calls and messages, including
                      calls using an automatic telephone dialing system, from Lohnz.com
                      and those acting on its behalf at the telephone number you have
                      provided above (including your cellular phone number); agree that
                      this consent applies even if the number you have provided is
                      currently By clicking “Get Qualified ,Submit or Funding Estimate”,
                      you (i) consent to receiving telemarketing calls and messages,
                      including calls using an automatic telephone dialing system, from
                      lohnz.com and those acting on its behalf at the telephone number
                      you have provided above (including your cellular phone number);
                      agree that this consent applies even if the number you have
                      provided is currently By clicking “Get Qualified ,Submit or
                      Funding Estimate”, you (i) consent to receiving telemarketing
                      calls and messages, including calls using an automatic telephone
                      dialing system, from Lohnz.com and those acting on its behalf at
                      the telephone number you have provided above (including your
                      cellular phone number); agree that this consent applies even if
                      the number you have provided is currently{" "}
                    </p>
                  </div>
                </div>
                {validationError &&
                  <div className='text-center mt-3' style={{ color: 'red' }}>
                    {validationError}
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="col-lg-6  col-12 d-flex justify-content-center align-items-center text-end position-relative z-3">
            <img src={img2} className="w-75" alt="" />
          </div>
        </div>

      </div>
    </>
  );
}

export default Step4;
