import './App.css';
import Home from './pages/Home';
import { Route, Routes } from 'react-router-dom';
import ApplicationStep1 from './components/ApplicationStep1';
import ApplicationStep2 from './components/ApplicationStep2';
import ApplicationStep3 from './components/ApplicationStep3';
import Thankspage1 from './pages/Thankspage1';
import About from './pages/About';
import Services from './pages/Services';
function App() {
  return (
   <>
   <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path='/about' element={<About/>}/>
    <Route path='/services' element={<Services/>}/>
    <Route path='/application1' element={<ApplicationStep1/>}/>
    <Route path='/application2' element={<ApplicationStep2/>}/>
    <Route path='/application3' element={<ApplicationStep3/>}/>
    <Route path='/thanks1' element={<Thankspage1/>}/>
   </Routes>
   </>
  );
}

export default App;
