import React from 'react'
import Navbar from '../components/Navbar'
import line from '../assets/img13.svg'
import img from '../assets/ime.png'
import img4 from '../assets/img14.svg'
import img5 from '../assets/img15.svg'
import img8 from '../assets/img16.svg'
import img9 from '../assets/img17.svg'
import img10 from '../assets/img18.svg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from '../components/Footer'
import ToDay from '../components/ToDay'
import Customer from '../components/Customer'
import { useEffect } from 'react'

const About = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Adds smooth scrolling animation
          });
      }, [])
    const settings = {
        dots: false,
        speed: 5000,
        autoplay: true,
        autoplaySpeed: 1500,
        infinite: false,
        speed: 500,
        slidesToShow: 2.5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
           {/* section--about */}
            <div className="conatiner-fluid bg-img  backgroundcolor position-relative pb-5">
                <img src={line} className="position-absolute inner_imges" alt="" />
                <Navbar />
               
                <div className="container pt-1">
                    <div className="row justify-content-center mt-5">
                        <div className="col-lg-6 col-md-6 col-12 ">
                            <h3 className='font_32 font_700 font-Bold-Ivy '>Here’s what <span className='coloryellow'>Lohnz</span> <br /> Does for you</h3>
                            <p className="font_20 font-DM-Sans color_gray">
                                Apply today and have access to capital from $20,000 up to <br /> $20
                                million with in hour’s of being qualified.
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-12 text-end">
                            <img src={img} className='w-75 position-relative z-3' alt="" />
                        </div>
                    </div>
                </div>
            <br /><br /><br />
            </div>
          
            {/* Who we are? */}
            <section className='second-Section'>
                <div className="container colorwhite pt-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-6 col-12 text-center">
                            <img src={img4} className='w-75' alt="" />
                        </div>
                        <div className="col-lg-7  col-md-10 col-12">
                            <h3 className='font_32 font_700 font-Bold-Ivy '>Who we are?</h3>
                            <p className="font_18 font-DM-Sans color_gray">At Lohnz, we’re committed to supporting small businesses with responsible financing solutions and world-class service.
                                We believe that small businesses are the backbone of the economy, and we’re dedicated to helping them thrive. We accomplish this by providing loans using cutting-edge technology, making the process as simple and streamlined as possible.
                                <p>

                                    We’re also transparent with our pricing and terms, so our customers can make informed decisions.
                                    And finally, we want to provide the best customer experience possible. That means providing excellence in all aspects of the customer’s Qualifier experience. We’re proud to stand behind our small business customers and help them grow and succeed.
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <br /><br />
            {/* Fuhnd Your Business */}
            <div className="container mt-5 pt-5">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 col-12">
                        <h3 className='font_32 font_700 font-Bold-Ivy '>Grow Your Business</h3>
                        <p className="font_18 font-DM-Sans">At Lohnz, we’re committed to supporting small businesses with responsible financing solutions and world-class service.
                            We believe that small businesses are the backbone of the economy, and we’re dedicated to helping them thrive. We accomplish this by providing loans using cutting-edge technology, making the process as simple and streamlined as possible.
                            <p>

                                We’re also transparent with our pricing and terms, so our customers can make informed decisions.
                                And finally, we want to provide the best customer experience possible. That means providing excellence in all aspects of the customer’s Qualifier experience. We’re proud to stand behind our small business customers and help them grow and succeed.
                            </p>
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 text-end">
                        <img src={img5} className='w-75' alt="" />
                    </div>

                </div>
            </div>
            <br /><br />
            <Customer />
            {/*--section-about-1stslider--*/}
           
            <ToDay />
            <Footer />
        </>
    )
}

export default About
