import React from 'react';
import logo from '../assets/logo.svg'
import { Link, NavLink } from 'react-router-dom';

const Navbar = (props) => {
    return (
        <>
            <div className="container">
                <header>
                    <nav className="navbar navbar-expand-lg ">
                        <div className="container-fluid">
                            <Link className="navbar-brand" to='/'>
                                <img src={logo} className="w-100" alt="" />
                            </Link>
                            <button
                                className="navbar-toggler"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="true"
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon">

                                </span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav mx-auto mb-2 mb-lg-0" style={{ display: props.display }}>
                                    <li className="nav-item d-flex">
                                        <NavLink to='/' className="nav-link " >
                                            Home
                                        </NavLink>
                                    </li>
                                    <li className="nav-item d-flex">
                                        <NavLink className="nav-link ms-lg-4 ms-md-0" to="/about">
                                            About us
                                        </NavLink>
                                    </li>
                                    <l className="nav-item d-flex">
                                        <NavLink className="nav-link ms-lg-4 ms-md-0" to="/services">
                                            Our Services
                                        </NavLink>
                                    </l>
                                </ul>
                                <div className="d-flex flex-wrap align-items-center" style={{ marginLeft: props.ms }}>
                                    <span className="nav-item">
                                        <a className="nav-link " href="mailto:lohnz@lohnz.com">
                                            <i className="fa-solid fa-envelope me-2" />
                                            Lohnz@lohnz.com
                                        </a>
                                    </span>
                                    <div className="vr  mx-3 mt-2" />
                                    <span className="nav-item me-2">
                                        <a className="nav-link" href="tel:310-307-1916">
                                            <i className="fa-solid fa-phone-volume me-2" />
                                            310-307-1916
                                        </a>
                                    </span>
                                    {/* <Link to="/">
                                    <button className=" btn-fill ms-3" type="button" style={{ display: props.dNone }} >
                                        Get Started
                                    </button>
                                    </Link>
                                    */}
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
            </div>
        </>
    );
}

export default Navbar;
