import React from 'react';
import img1 from '../assets/img3.svg'
import img2 from '../assets/img4.svg'
import img3 from '../assets/img5.svg'
import img4 from '../assets/img6.svg'

const Bannar = () => {
    return (
        <>
            <div
                className="container pt-5 transform position-relative"
                style={{ zIndex: 3 }}
            >
                <div className="row justify-content-center">
                    <div className="row justify-content-center text-center g-0 shadow-box">
                        <p className="font_24 mb-0 color_gray">
                            If you have a Business Bank Account and over 1 year in business
                        </p>
                        <h3 className="font_24 font_700 font-Bold-Ivy color_gray">
                            {" "}
                            we can help you get matched with the best business capital for you.
                        </h3>
                        <div className="col-lg-3 col-md-6 col-sm-8 col-12">
                            <p className="font_14 mt-5 color_gray">
                                {" "}
                                <img src={img1} className="me-2" alt="" />
                                Options from over 50 Lenders
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-8 col-12">
                            <p className="font_14 mt-5 color_gray">
                                {" "}
                                <img src={img2} className="me-2" alt="" />
                                No Hard Credit Pulls
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-8 col-12">
                            <p className="font_14 mt-5 color_gray">
                                {" "}
                                <img src={img3} className="me-2" alt="" />
                                Get funded $20,000 up to $20 Million
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-8 col-12">
                            <p className="font_14 mt-5 color_gray">
                                {" "}
                                <img src={img4} className="me-2" alt="" />
                                Get money in as little as 24 hrs
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Bannar;
