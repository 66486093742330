import React from 'react';
import Navbar from '../components/Navbar';
import img from '../assets/img13.svg'
import { useNavigate } from 'react-router-dom';

const Thankspage1 = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className="container-fluid p-0 bg-imge position-relative d-flex flex-column justify-content-between" style={{minHeight:'100vh'}}>
                <img src={img} className="position-absolute inner_imges" alt="" />
                <div className="container">
                    <Navbar display='none' ms='auto' dNone='none'/>
                    {/* <p class="btn_empty font_15 previous mt-3 ps-3 cursor-pointer" onClick={() =>  navigate(-1)}><span><i class="fa-solid fa-arrow-left"></i></span> Back</p> */}
                    </div>
                <br />
                <br />
                <br className="d-lg-block d-none" />
                <br className="d-lg-block d-none" />
                {/* <br class="d-lg-block d-none">
  <br class="d-lg-block d-none"> */}
                <div className="container">
                    {/* ---------main-sec-1----- */}
                    <div className="row justify-content-center  ">
                        <div
                            className="col-lg-8 col-md-10 col-12 text-center mt-5 position-relative "
                            style={{ zIndex: 3 }}
                        >
                            <h3 className="font_36 font_700 font-DM-Sans">
                                Thank You For Submitting This Application
                            </h3>
                            <p className="font_16 font-DM-Sans mt-4">
                                your file is being processed, if qualified, you will receive a offer
                                with in 2-24 hours unless the underwriters request further
                                documentation in which a rep will reach out and request what’s needed
                                to get your file complete to be processed.
                            </p>
                          
                                <button className="btn-fill font_15 w-25 ok_btn mt-4" type="submit" onClick={() =>  navigate('/')}>
                                    ok
                                </button>
                  
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br className="d-lg-block d-none" />
                <br className="d-lg-block d-none" />
                <br className="d-lg-block d-none" />
                {/*footer*/}
                <footer className="container">
                    <p className="font_20 font-DM-Sans">
                        <i className="fa-solid fa-phone-volume me-2" />
                        Need Help?
                    </p>
                    <h3 className="font_22 font_700 font-DM-Sans">310-307-1916</h3>
                    <hr style={{ border: "1px solid #FFFFFF" }} />
                    <div className="text-center">
                        <p>© Copyright 2023 | LOHNZ. All Rights Reserved.</p>
                    </div>
                </footer>
            </div>

        </>
    );
}

export default Thankspage1;
