import React from 'react';
import img1 from '../assets/pic1.svg'
import img2 from '../assets/pic4.svg'

const Step2 = ({ revenue, industry, getrevenue, getindustry, validationError,  getcreditScore, creditScore}) => {
  return (
    <>
      <div className="container">
        <div className="row flex-d-col " >
          <div className="col-lg-6 mt-lg-0 mt-4 col-12 text-start">
            <div>
              <img src={img1} alt="" />
            </div>
            <h3 className="font_28 font_600 mt-2 font-Bold-Ivy ">Need Financing?</h3>
            <p className="font_16 mt-3 font-DM-Sans color_gray">
              Please specify your details below.
            </p>
            <p className="font_16 mt-4 mb-0 font-DM-Sans color_gray">
              Your Monthly Gross Revenue?
            </p>
            <div className="row">
              <div className="col-lg-8  col-12">
                <div className="input-group mb-3">
                  <label
                    className="input-group-text mb-0 input-group-text font_24 font_700 font-Bold-Ivy  bg-white"
                    style={{ color: "#009934" }}
                  >
                    $
                  </label>
                  <select
                    className="form-select Resp_16 input_field font_weight_5"
                    id="inputGroupSelect01"
                    value={revenue}
                    onChange={getrevenue}
                  >
                    <option selected="" value='' />
                    <option value="Under 15,000">Under 15,000 </option>
                    <option value="15k-30k">15k-30k</option>
                    <option value="30k-50k">30k-50k</option>
                    <option value="50k-100k">50k-100k</option>
                    <option value="100k-150k">100k-150k </option>
                    <option value="150k-250k">150k-250k</option>
                    <option value="250k-500k">250k-500k</option>
                    <option value="500k-1 Million">500k-1 Million </option>
                    <option value="1-Million-2.5-Million">1 Million- 2.5 Million</option>
                    <option value="2.5-million-5-Million">2.5 million- 5 Million</option>
                    <option value="Over-5-Million">Over 5 Million</option>
                  </select>
                </div>
                <div className={` ${revenue == 'Under 15,000' ? 'd-block' : 'd-none'}`}>

                  <p className="font_16 mt-2 mb-0 font-DM-Sans color_gray">
                    What is your credit score?
                  </p>
                  <div className='input-group mb-2 '>
                    <label
                      className="input-group-text mb-0 input-group-text font_24 font_700 font-Bold-Ivy  bg-white"
                      style={{ color: "#009934" }}
                    >
                      $
                    </label>
                    <select
                      className="form-select Resp_16 input_field font_weight_5"
                      id="inputGroupSelect01"
                      value={creditScore}
                      onChange={getcreditScore}
                    >
                      <option value="" selected="">Select
                      </option>
                      <option value="Under-600">Under 600</option>
                      <option value="600-640">600-640</option>
                      <option value="641-679">641-679</option>
                      <option value="680-719">680-719</option>
                      <option value="720 Plus">720 Plus</option>
                    </select>
                  </div>
                </div>


                <label className="font_16 mt-4 mb-0 font-DM-Sans color_gray">
                  What industry are you in?
                </label>
                <div className="input-group mb-3">
                  <select
                    className="form-select Resp_16 input_field font_weight_5"
                    id="inputGroupSelect01"
                    value={industry}
                    onChange={getindustry}
                  >
                    <option value='' selected="">Import and Export</option>
                    <option value="Agriculture Forestry,Fishing & Hunting">Agriculture Forestry,Fishing & Hunting</option>
                    <option value="Arts Entertainment, & Recration">Arts Entertainment, & Recration</option>
                    <option value="Adult Entertainment">Adult Entertainment</option>
                    <option value="Gambling">Gambling</option>
                    <option value="Automobile Dealers & Parts">Automobile Dealers & Parts </option>
                    <option value="Construction">Construction</option>
                    <option value="Ecommerce">Ecommerce</option>
                    <option value="Education">Education </option>
                    <option value="Finance & Insurance">Finance & Insurance</option>
                    <option value="Healthcare">Healthcare</option>
                    <option value="Social Assistance">Social Assistance</option>
                    <option value="IT,Media or Publishing">IT,Media or Publishing</option>
                    <option value="Legal Services">Legal Services</option>
                    <option value="Mining (except Oil and Gas)">Mining (except Oil and Gas)</option>
                    <option value="Oil and Gas Extraction">Oil and Gas Extraction</option>
                    <option value="Manufacturing">Manufacturing</option>
                    <option value="Political, Governmental, or Public Organizations">Political, Governmental, or Public Organizations</option>
                    <option value="Real Estate">Real Estate</option>
                    <option value="Religious Organizations">Religious Organizations</option>
                    <option value="Restaurants and Food Services">Restaurants and Food Services</option>
                    <option value="Retail Stores">Retail Stores</option>
                    <option value="Firearm Sales">Firearm Sales</option>
                    <option value="Gas Stations">Gas Stations</option>
                    <option value="Transportation and Warehousing">Transportation and Warehousing</option>
                    <option value="Freight Trucking">Freight Trucking</option>
                    <option value="Travel Agencies">Travel Agencies</option>
                    <option value="Utilities">Utilities</option>
                    <option value="Wholesales Trade">Wholesales Trade</option>
                    <option value="All Other">All Other</option>
                  </select>
                </div>
                {validationError &&
                  <div className='text-center mt-3' style={{ color: 'red' }}>
                    {validationError}
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="col-lg-6  col-12 d-flex justify-content-center align-items-center text-end position-relative z-3">
            <img src={img2} className="w-75" alt="" />
          </div>
        </div>

      </div>


    </>
  );
}

export default Step2;
