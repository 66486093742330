import React from 'react';
import img1 from '../assets/pic20.svg'
import img2 from '../assets/img7.svg'
import img3 from '../assets/img9.svg'
import img4 from '../assets/img10.svg'
import img5 from '../assets/img8.svg'


const Business = () => {
    return (
        <>
            <>
                {/*section-Your Business*/}
                <div className="container-fluid position-relative">
                    <img
                        src={img1}
                        className="position-absolute inner_imgee w-25"
                        alt=""
                    />
                    <div className="container text-center  pt-5 centermobil">
                        <h3 className="font_42 font_700 font-Bold-Ivy">
                            We Help , <span className="green_color">Your Business Grow</span>
                        </h3>
                        <p className="font_16 font-DM-Sans ">
                            Complete our three step process through our secure-based application.
                        </p>
                        <div className="row justify-content-center pt-5">
                            <div className="col-lg-6 col-md-6 col-12 text-start">
                                <div>
                                    <img src={img2} className="w-75" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="row text-start">
                                    <div className="col-lg-6 col-md-6 col-12 ">
                                        <img src={img3} className="inner-imges" alt="" />
                                        <h3 className="font_24 font_600 font-Bold-Ivy mt-2">
                                            1.Fill Out The Form
                                        </h3>
                                        <p className="font_16 font-DM-Sans mt-2">
                                            Apply directly through our secure-based application. It only
                                            takes seconds to complete.
                                        </p>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12 ">
                                        <img src={img4} className="inner-imges" alt="" />
                                        <h3 className="font_24 font_600 font-Bold-Ivy " mt-2="">
                                            2. Sit Tight{" "}
                                        </h3>
                                        <p className="font_16 font-DM-Sans mt-2">
                                            Choose your capital and get funded in as little as 24 hours....
                                            This is some text to fill third line
                                        </p>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-12 mt-2">
                                        <img src={img5} className="inner-imges" alt="" />
                                        <h3 className="font_24 font_600 font-Bold-Ivy mt-2">
                                            3.Pick Your Capital
                                        </h3>
                                        <p className="font_16 font-DM-Sans mt-2 color_gray">
                                            We’ll evaluate your business needs and revenue to provide you
                                            with the best options possible.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
            </>

        </>
    );
}

export default Business;
