import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import { Link, json, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Alert } from 'reactstrap';
import 'react-toastify/dist/ReactToastify.css';
import ButtonLoader from './ButtonLoader';


const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad', 'Create an ad1'];

export default function MultiStep() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // ---------GET--DATA---IN---LOCALSTORAGE------
  // ---------STEP---ONE----
  const storeFirstStep = localStorage.getItem('stepOne');
  const parsedStepOne = storeFirstStep ? JSON.parse(storeFirstStep) : null;
  const stepOneAmount = parsedStepOne ? parsedStepOne.amount_requested : '';
  const stepOneUse = parsedStepOne ? parsedStepOne.where_you_want_to_use : '';
  // ------STEP-----TWO------
  const storeSenondStep = localStorage.getItem('stepTwo');
  const parsedStepTwo = storeSenondStep ? JSON.parse(storeSenondStep) : null;
  const stepRevenue = parsedStepTwo ? parsedStepTwo.average_gross_revenue : '';
  const stepCreditScore = parsedStepTwo ? parsedStepTwo.credit_score : '';
  const stepIndustry = parsedStepTwo ? parsedStepTwo.industry : '';

  // ------STEP-----THREE------
  const storeThirdStep = localStorage.getItem('stepThree');
  const parsedStepThree = storeThirdStep ? JSON.parse(storeThirdStep) : null;
  const steptime = parsedStepThree ? parsedStepThree.time_in_business : '';
  const steploan = parsedStepTwo ? parsedStepTwo.how_soon_you_want_loan : '';

  // ------STEP-----FOURE------
  const storeFourStep = localStorage.getItem('stepFourData');
  const parsedStepFour = storeFourStep ? JSON.parse(storeFourStep) : null;
  const stepfirstname = parsedStepFour ? parsedStepFour.firstname : '';
  const stepbusinessName = parsedStepFour ? parsedStepFour.business_name : '';
  const stepEmail = parsedStepFour ? parsedStepFour.email : '';
  const stepPhoneNumber = parsedStepFour ? parsedStepFour.phone_number : '';

  // ------STATES--------
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [amount, setAmount] = useState(stepOneAmount);
  const [use, setUse] = useState(stepOneUse);
  const [revenue, setRevenue] = useState(stepRevenue);
  const [creditScore, setCreditScore] = useState(stepCreditScore);
  const [industry, setIndustry] = useState(stepIndustry);
  const [time, setTime] = useState(steptime);
  const [loan, setLoan] = useState(steploan);
  const [firstname, setFirstname] = useState(stepfirstname);
  const [businessName, setBusinessName] = useState(stepbusinessName);
  const [email, setEmail] = useState(stepEmail);
  const [phoneNumber, setPhoneNumber] = useState(stepPhoneNumber);
  const [halfSubmitted, setHalfSubmitted] = useState('');
  const [stepId, setStepId] = useState('');
  const [validationError, setValidationError] = useState(null);

  // ------refresh-----step------------

  // Retrieve the firstname from localStorage when the component mounts



  // ------api--End----------

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = (e) => {
    e.preventDefault();
    setIsLoading(true)
    if (activeStep == 0) {

      if ((amount.length > 0) && (use.length > 0)) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setIsLoading(false)
        // ------------step-1-----

        let data = {
          "amount_requested": amount,
          "where_you_want_to_use": use,
        };

        let stepOne = {
          method: 'post',
          async:false,
          url: 'https://lohnz.codingsolution24.com/api/create-step-one',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios(stepOne)
          .then((res) => {
            if (res.data.status) {
              // console.log(JSON.stringify(res.data.data));
              // toast.success("Res status is true", {
              //   position: toast.POSITION.TOP_LEFT,
              //   autoClose: 3000
              // })
              setStepId(res.data.data.id);
              localStorage.setItem('id', JSON.stringify(res.data.data.id));
              localStorage.setItem('stepOne', JSON.stringify(res.data.data));
              setValidationError(null);
            }
            else {
              // console.log(res.data.message);
              // toast.error(res.data.message, {
              //   position: toast.POSITION.TOP_RIGHT,
              //   autoClose: 3000
              // });
            }


          })
          .catch((error) => {
            // console.log(error);
            // toast(error.message);
            // setError(error.message)
          });
      }
      else {
        setValidationError(<Alert color="danger">
          Please fill up complete details
        </Alert>)
        setIsLoading(false)
      }
    }
    else if (activeStep == 1) {
      // ------------step-2-----
      if ((revenue != '') && (industry != '')) {
        if (revenue == 'Under 15,000' && creditScore === '') {
          setValidationError(<Alert color="danger">
            Please select a credit score.'
          </Alert>); // Show validation error if credit score is not selected for 'Under 15,000' revenue
          return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setIsLoading(false)
        let dataStepTwo = {
          "id": stepId,
          "credit_score": creditScore,
          "average_gross_revenue": revenue,
          "industry": industry,
        };

        let stepTwo = {
          method: 'post',
          url: 'https://lohnz.codingsolution24.com/api/create-step-one',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          data: dataStepTwo
        };

        axios(stepTwo)
          .then((res) => {
            if (res.data.status) {
              localStorage.setItem('stepTwo', JSON.stringify(res.data.data));
              setValidationError(null)
            }
            else {
              // console.log(res.data.message);
              // toast.error(res.data.message, {
              //   position: toast.POSITION.TOP_RIGHT,
              //   autoClose: 3000
              // });
            }
          })
          .catch((error) => {
            // console.log(error);
            // toast(error.message);
            // setError(error.message)
          });
      }
      else {
        setValidationError(<Alert color="danger">
          Please fill up complete details
        </Alert>)
        setIsLoading(false)
      }
    }
    else if (activeStep == 2) {
      if ((time != '') && (loan != '')) {

        // -------step---3--------
        let dataStepThree = JSON.stringify({
          "id": stepId,
          "time_in_business": time,
          "how_soon_you_want_loan": loan,
        });
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setIsLoading(false)
        let StepThree = {
          method: 'post',
          url: 'https://lohnz.codingsolution24.com/api/create-step-one',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          data: dataStepThree
        };

        axios(StepThree)
          .then((res) => {
            if (res.data.status) {
              localStorage.setItem('stepThree', JSON.stringify(res.data.data));
            }
            else {
              // console.log(res.data.message);
              // toast.error(res.data.message, {
              //   position: toast.POSITION.TOP_RIGHT,
              //   autoClose: 3000
              // });
            }
          })
          .catch((error) => {
            // console.log(error);
            // toast(error.message);
            // setError(error.message)
          });
      }
      else {
        setValidationError(<Alert color="danger">
          Please fill up complete details
        </Alert>)
        setIsLoading(false)
      }
    }
    else if (activeStep == 3) {
      if ((firstname.length > 0) && (businessName.length > 0) && (email.length > 0) && (phoneNumber.length > 0)) {
        // Email validation regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(email)) {
          setValidationError(<Alert color="danger">
            Please enter a valid email address.
          </Alert>);
          return;
        }
        let dataStepThree = JSON.stringify({
          "id": stepId,
          "half_form_submitted": 1,
          "firstname": firstname,
          "business_name": businessName,
          "email": email,
          "phone_number": phoneNumber,
        });
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setIsLoading(false)
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Adds smooth scrolling animation
        });
        let StepThree = {
          method: 'post',
          url: 'https://lohnz.codingsolution24.com/api/create-step-one',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          data: dataStepThree
        };

        axios(StepThree)
          .then((res) => {
            if (res.data.status) {
              localStorage.removeItem('stepOne')
                localStorage.removeItem('stepTwo')
                localStorage.removeItem('stepThree')
                localStorage.removeItem('stepFourData')
            }    
            else {
              // console.log(res.data.message);
              // toast.error(res.data.message, {
              //   position: toast.POSITION.TOP_RIGHT,
              //   autoClose: 3000
              // });
            }
          })
          .catch((error) => {
            // console.log(error);
            // toast(error.message);
            // setError(error.message)
          });
      }
      else {
        setValidationError(<Alert color="danger">
          Please fill up complete details
        </Alert>)
        setIsLoading(false)
      }
    }

  };
  const handlecompleteProcess = (e) => {
    e.preventDefault();
    
    if (activeStep == 3) {
      if ((firstname.length > 0) && (businessName.length > 0) && (email.length > 0)
        && (phoneNumber.length > 0)) {

        // Email validation regular expression
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if    (!emailRegex.test(email)) {
          setValidationError(<Alert color="danger">
            Please enter a valid email address.
          </Alert>);
          return;
        }
        // -------step---3--------
        let dataStepThree = JSON.stringify({

          "id": stepId,
          "half_form_submitted": 0,
          "firstname": firstname,
          "business_name": businessName,
          "email": email,
          "phone_number": phoneNumber,
        });
        let StepThree = {
          method: 'post',
          url: 'https://lohnz.codingsolution24.com/api/create-step-one',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          data: dataStepThree
        };
        navigate('/application1');
        axios(StepThree)
          .then((res) => {
            if (res.data.status) {
          
              localStorage.setItem('stepFourData', JSON.stringify(res.data.data));
              setIsLoading(false)
            }
            else {
              // console.log(res.data.message);
              // toast.error(res.data.message, {
              //   position: toast.POSITION.TOP_RIGHT,
              //   autoClose: 3000
              // });
            }
          })
          .catch((error) => {
            // console.log(error);
            // toast(error.message);
            // setError(error.message)
          });


      }
      else {
        setValidationError(<Alert color="danger">
          Please fill up complete details
        </Alert>)
      }
    }
  }

  const handelSubmit = (e) => {
    e.preventDefault();
    // console.log(amount);
  }
  const getamount = (e) => {
    console.log(e.target.value.replace(/\D/g, ''),'amount')
    let rawValue = e.target.value.replace(/,/g, '');
    if(parseFloat(rawValue.replace(/,/g, ''))){

      setAmount(parseFloat(rawValue.replace(/,/g, '')).toLocaleString());
    }else{
      setAmount(0)
    }
  }
  const getuse = (e) => {
    setUse(e.target.value);
  }
  const getrevenue = (e) => {
    setRevenue(e.target.value);
  }
  const getcreditScore = (e) => {
    setCreditScore(e.target.value);
  }
  const getindustry = (e) => {
    setIndustry(e.target.value);
  }
  const gettime = (e) => {
    setTime(e.target.value);
  }
  const getloan = (e) => {
    setLoan(e.target.value);
  }
  const getFirstname = (e) => {
    setFirstname(e.target.value);
  }
  const getBusinessName = (e) => {
    setBusinessName(e.target.value);
  }
  const getEmail = (e) => {
    setEmail((e.target.value).toLowerCase());
  }
  const getPhoneNumber = (e) => {
    let inputPhoneNumber = e.target.value;
    let formattedNumber = formatPhoneNumber(inputPhoneNumber);
    setPhoneNumber(formattedNumber);
  }
  const formatPhoneNumber = (phoneNumber) => {
    // Remove all non-digit characters from the input
    const digitsOnly = phoneNumber.replace(/\D/g, '');

    // Apply the USA phone number format based on the number of digits entered
    let formattedNumber = '';
    if (digitsOnly.length < 4) {
      formattedNumber = digitsOnly;
    } else if (digitsOnly.length < 7) {
      formattedNumber = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3)}`;
    } else {
      formattedNumber = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6, 10)}`;
    }

    return formattedNumber;
  };
  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return <Step1 getamount={getamount} amount={amount} getuse={getuse} use={use}
          validationError={validationError} />;
      case 1:
        return <Step2 creditScore={creditScore} getcreditScore={getcreditScore} industry={industry} revenue={revenue} getindustry={getindustry} getrevenue={getrevenue} validationError={validationError} />;
      case 2:
        return <Step3 loan={loan} time={time} getloan={getloan} gettime={gettime}
          validationError={validationError} />;
      case 3:
        return <Step4 firstname={firstname} businessName={businessName} email={email}
          phoneNumber={phoneNumber} validationError={validationError} getFirstname={getFirstname}
          getBusinessName={getBusinessName} getEmail={getEmail} getPhoneNumber={getPhoneNumber} />;
      default:
        return <div>Not Found</div>;
    }
  }

  

  const handleClick = () => {
    setIsLoading(true);

    // Simulate an asynchronous operation
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <ToastContainer />
      <Stepper className='Numbers' activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Step5 />
          {/* <Box sx={{ display: 'none', flexDirection: 'row', pt: 2 }}>
            <Box  />
            <Button onClick={handleReset}>Reset</Button>
          </Box> */}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <form onSubmit={handelSubmit}>
            {_renderStepContent(activeStep)}
            <div className='ps-3' >
              {
                activeStep === 3 && (
                  <div className='mb-3'>
                    <button className="btn-fill w-320" onClick={handlecompleteProcess} type='submit'>
                      
                      Complete the Application Process</button>
                  </div>
                )
              }
              <button onClick={handleNext} className={`${activeStep == 3 ? 'btn-fill-empty w-320' : 'btn-fill'}`}>
                {activeStep === steps.length - 1 ? isLoading ? <ButtonLoader/> :'Submit Details , We’ll reach out to you.' : isLoading ? <ButtonLoader/> : 'Next'}
              </button>

            </div>
          </form>
        </React.Fragment>
      )}
    </Box>
  );
}
