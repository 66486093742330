import React from 'react';
import img1 from '../assets/pic2.svg'
import img2 from '../assets/pic4.svg'
const Step3 = ({ getloan, gettime, loan, time, validationError }) => {
  return (
    <>
      <div className="container">
        <div className="row flex-d-col " >
          <div className="col-lg-6 mt-lg-0 mt-4 col-12 text-start">
            <div>
              <img src={img1} alt="" />
            </div>
            <h3 className="font_28 font_600 mt-2 font-Bold-Ivy ">Need Financing?</h3>
            <p className="font_16 mt-3 font-DM-Sans color_gray">
              Please specify your details below.
            </p>
            <p className="font_16 mt-4 mb-0 font-DM-Sans color_gray">
              How long you have been in business?
            </p>
            <div className="row">
              <div className="col-lg-8  col-12">
                <div className="input-group mb-3">
                  <select
                    className="form-select Resp_16 input_field font_weight_5"
                    id="inputGroupSelect01"
                    value={time}
                    onChangeCapture={gettime}
                  >
                    <option selected="" />
                    <option value="10-plus-years">10 plus years</option>
                    <option value="Under-1-year"> Under 1 year</option>
                    <option value="1-3 years">1 - 3 years</option>
                    <option value="3-5 years">3 - 5 years</option>
                    <option value="5-10 years">5 - 10 years</option>
                    <option value="10-plus years">10 plus years</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label className="font_16 mt-4 mb-0 font-DM-Sans color_gray">
                  How soon are you looking moving on this?
                  </label>
                  <div className="input-group mb-3">
                    <select
                      className="form-select Resp_16 input_field font_weight_5"
                      id="inputGroupSelect01"
                      value={loan}
                      onChange={getloan}
                    >
                      <option selected="" value=''>today</option>
                      <option value="Tomorrow" >Tomorrow</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Today">Today</option>
                      <option value="Tomorrow">Tomorrow</option>
                      <option value="After-1-Week">After 1 Week</option>
                      <option value="In-A-Month">In A Month</option>
                      <option value="Not-Yet-Decided">Not Yet Decided</option>
                    </select>
                  </div>
                </div>
                {validationError &&
                  <div className='text-center mt-3' style={{ color: 'red' }}>
                    {validationError}
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="col-lg-6  col-12 d-flex justify-content-center align-items-center text-end position-relative z-3">
            <img src={img2} className="w-75" alt="" />
          </div>
        </div>

      </div>


    </>
  );
}

export default Step3;
