import React from 'react';
// import Navbar from '../components/Navbar';
import Bannar from '../components/Bannar';
import Business from '../components/Business';
import Customer from '../components/Customer';
import Services from '../components/Services';
import ToDay from '../components/ToDay';
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import { useEffect } from 'react';

const Home = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Adds smooth scrolling animation
          });
      }, [])


      const scrollToTop = ()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Adds smooth scrolling animation
          });
      }
    return (
        <>
            <HeroSection />
            <Bannar />
            <Business />
            <Customer />
            <Services />
            <ToDay scrollToTop={scrollToTop}/>
            <Footer />
        </>

    );
}

export default Home;
