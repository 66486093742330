import React from 'react';
import logo from '../assets/logo.svg'
import img from '../assets/pic15.svg'
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
            <>
                {/* --footer-- */}
                <footer className="container-fluid pt-3">
                    <div className="container pt-3 d-flex flex-wrap">
                        <Link to="/">
                            <img src={logo} alt="" />
                        </Link>

                        <div className=" d-flex ms-lg-auto ms-md-auto ms-sm-auto ">
                            <Link to="/#"> <p className="mt-3">Home</p></Link>
                            <Link to="/about/#"> <p className="mt-3 ms-4">About us</p></Link>
                            <Link to="/services/#"><p className="mt-3 ms-4">Our Services</p></Link>
                        </div>
                        <div className="justify-content-between d-flex flex-wrap ms-lg-4">
                            <a href='mailto:lohnz@lohnz.com'>
                                <p className="mt-3 ms-lg-3 ">
                                    <i className="fa-solid fa-envelope me-2" />
                                    Lohnz@lohnz.com
                                </p>
                            </a>
                            <a className='phone-number' href="tel:310-307-1916">
                                <p className="mt-3 ms-lg-3 ms-md-3 ms-sm-3">
                                    <i className="fa-solid fa-phone-volume me-2" />
                                    310-307-1916
                                </p>
                            </a>
                           
                        </div>
                    </div>
                    <hr style={{ border: "1px solid #E2E2E2" }} />
                    <div className="text-center">
                        <p>© Copyright 2023 | LOHNZ. All Rights Reserved.</p>
                    </div>
                </footer>
            </>


    );
}

export default Footer;
