import React, { useState } from 'react';
import Navbar from '../components/Navbar'
import line from '../assets/img13.svg'
import img1 from '../assets/pic9.svg'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import ButtonLoader from './ButtonLoader';

const ApplicationStep1 = () => {
    const navigate = useNavigate();
  // ---------GET--DATA---IN---LOCALSTORAGE------
  const storeApplicationOne = localStorage.getItem('applicationOne');
  const parsedstoreApplicationOne = storeApplicationOne ? JSON.parse(storeApplicationOne) : null;
  const steplastname = parsedstoreApplicationOne ? parsedstoreApplicationOne.lastname : '';
  const stepOwnerAddress = parsedstoreApplicationOne ? parsedstoreApplicationOne.owner_address : '';
  const stepOwnerCity = parsedstoreApplicationOne ? parsedstoreApplicationOne.owner_city : '';
  const stepOwnerState = parsedstoreApplicationOne ? parsedstoreApplicationOne.owner_state : '';
  const stepOwnerZip = parsedstoreApplicationOne ? parsedstoreApplicationOne.owner_zip : '';
  const stepOwnerDateOfBirth = parsedstoreApplicationOne ? parsedstoreApplicationOne.owner_date_of_birth : '';
  const stepSecurity = parsedstoreApplicationOne ? parsedstoreApplicationOne.social_security : '';
  const stepPercentage = parsedstoreApplicationOne ? parsedstoreApplicationOne.ownership_percentage : '';
  const stepbankruptcies = parsedstoreApplicationOne ? parsedstoreApplicationOne.bankruptcies : '';
  const stepjudgements = parsedstoreApplicationOne ? parsedstoreApplicationOne.judgements : '';

    const [lastname, setLastname] = useState(steplastname);
    const [ownerAddress, setOwnerAddress] = useState(stepOwnerAddress);
    const [city, setCity] = useState(stepOwnerCity);
    const [state, setState] = useState(stepOwnerState);
    const [zip, setZip] = useState(stepOwnerZip);
    const [date_of_birth, setDate_of_birth] = useState(stepOwnerDateOfBirth);
    const [social_security, setSocial_securit] = useState(stepSecurity);
    const [percentage, setPercentage] = useState(stepPercentage);
    const [bankruptcies, setBankruptcies] = useState(stepbankruptcies);
    const [judgements, setJudgements] = useState(stepjudgements);
    const [validationError, setValidationError] = useState(null);
    const stepsdata = JSON.parse(localStorage.getItem('stepFourData'))
    const Stepsid = JSON.parse(localStorage.getItem('id'))
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        if ((lastname.length > 0) && (ownerAddress.length > 0) && (city.length > 0) &&
         (state.length > 0) && (zip.length > 0)
            && (date_of_birth.length > 0) && (social_security.length > 0)
            && (percentage.length > 0) && (bankruptcies != '') && (judgements != '')) {

            let data = {
                "id": Stepsid,
                //   "firstname": firstName,
                "lastname": lastname,
                "owner_address": ownerAddress,
                "owner_city": city,
                "owner_state": state,
                "owner_zip": zip,
                "owner_date_of_birth": date_of_birth,
                "social_security": social_security,
                "ownership_percentage": percentage,
                "bankruptcies": bankruptcies,
                "judgements": judgements,
            };
            try {
                const response = await axios.post('https://lohnz.codingsolution24.com/api/create-step-one', data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });
                console.log(JSON.stringify(response.data));
                localStorage.setItem('applicationOne', JSON.stringify(response.data.data));

                // setStepId(response.data.data.id);
                navigate('/application2');
            } catch (error) {
                console.log(error);
            }
            setValidationError(null);

        }
        else {
            setValidationError(<div className="alert alert-danger text-start" role="alert">
                Please complete the all detail
            </div>)
        }

    }
    return (
        <>
            <div
                className="container-fluid p-0 position-relative "
                style={{ backgroundColor: "#EFF2ED" }}
            >
                <img src={line} className="position-absolute iner_img" alt="" />
                {/* -------Navbar----------- */}
                <div
                    className="container-fluid position-relative bg_color"
                    style={{ zIndex: 3 }}
                >
                    <div className="container">
                        <Navbar display='none' ms='auto' dNone='none' />
                    </div>
                </div>
                {/* ---------main-sec-1----- */}
                <div className="container  pt-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-8 col-12 mt-3">

                            <p className="btn_empty font_15 previous cursor-pointer" onClick={() => navigate(-1)}>
                                <span>
                                    <i className="fa-solid fa-arrow-left  me-2" />
                                </span>{" "}
                                Back
                            </p>

                            <br className='d-lg-block d-md-block d-none' />  <br className='d-lg-block d-md-block d-none' />
                            <div className="tab">
                                <ul className="d-block nav nav-pills">
                                    <div className="nav-item font_15 mt-5 d-flex">
                                        <div>
                                            <img src={img1} alt="" />
                                        </div>
                                        <div className="flex-column d-flex justify-content-between ms-4">
                                            <p className="mb-0">Business Information</p>
                                            <p className="mb-0">Owner information</p>
                                            <p className="mb-0">Capital information</p>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-10 col-12 application-fieled mt-lg-0 mt-md-0  mt-4">
                            <h3 className=" mb-3 font_32 font_700 font-Bold-Ivy text-center">
                                Tell us about yourself
                            </h3>
                            <p
                                style={{ color: "#525252" }}
                                className="DM_fonts font_15 text-center"
                            >
                                Your business complete information will help us to make your process
                                fast
                            </p>

                            <form onSubmit={handleSubmit} className="row DM_fonts">
                                <div className="col-lg-6 col-md-6 col-12 mt-4">
                                    <div className="row justify-content-center">
                                        <div className="col-6  ">
                                            <label style={{ color: "#525252" }} htmlFor="state" className=''>
                                                First Name
                                            </label>
                                            <input
                                                className=" font_15 form-control input_field"
                                                type="text"
                                                id="state"
                                                style={{ fontWeight: 500 }}
                                                value={stepsdata.firstname}
                                                readOnly=""
                                            />
                                        </div>
                                        <div className="col-6 ps-lg-0">
                                            <label style={{ color: "#525252" }} htmlFor="zip">
                                                Last Name
                                            </label>
                                            <input
                                                className=" font_15 form-control input_field"
                                                type="text"
                                                id="zip"
                                                style={{ fontWeight: 500 }}
                                                value={lastname}
                                                onChange={(e) => setLastname(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12   mt-4">
                                    <label htmlFor="Addr" style={{ color: "#525252" }}>
                                        Address
                                    </label>
                                    <input
                                        className="font_15 form-control Satoshi_fonts input_field font_15"
                                        type="text"
                                        id="Addr"
                                        value={ownerAddress}
                                        onChange={(e) => setOwnerAddress(e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12  mt-4">
                                    <label style={{ color: "#525252" }} htmlFor="cit">
                                        City
                                    </label>
                                    <input
                                        className="font_15 form-control input_field"
                                        type="text"
                                        id="cit"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12  mt-4">
                                    <div className="row justify-content-center">
                                        <div className="col-6 ">
                                            <label style={{ color: "#525252" }} htmlFor="state">
                                                State
                                            </label>
                                            <input
                                                className=" font_15 form-control input_field"
                                                type="text"
                                                id="state"
                                                value={state}
                                                onChange={(e) => setState(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-6 ">
                                            <label style={{ color: "#525252" }} htmlFor="zip">
                                                ZIP
                                            </label>
                                            <input
                                                className="font_15 form-control input_field"
                                                type="text"
                                                id="zip"
                                                value={zip}
                                                onChange={(e) => setZip(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12  mt-4">
                                    <label style={{ color: "#525252" }}>Date of birth</label>
                                    <input
                                        className="font_15 form-control input_field"
                                        type='date'
                                        style={{ fontWeight: 500 }}
                                        value={date_of_birth}
                                        onChange={(e) => setDate_of_birth(e.target.value)}
                                    >

                                    </input>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12   mt-4">
                                    <label style={{ color: "#525252" }} htmlFor="socal">
                                        Social Security
                                    </label>
                                    <input
                                        className=" font_15 form-control input_field"
                                        type="text"
                                        id="socal"
                                        value={social_security}
                                        onChange={(e) => setSocial_securit(e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12   mt-4">
                                    <label style={{ color: "#525252" }}>Phone Number</label>
                                    <input
                                        className="font_15 form-control Satoshi_fonts input_field font_15"
                                        type="text"
                                        value={stepsdata.phone_number}
                                        style={{ fontWeight: 500 }}
                                        readOnly=""
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12   mt-4">
                                    <label style={{ color: "#525252" }}>Email ID</label>
                                    <input
                                        className=" font_15 form-control Satoshi_fonts input_field font_15"
                                        type="text"
                                        value={stepsdata.email}
                                        style={{ fontWeight: 500 }}
                                        readOnly=""
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12   mt-4">
                                    <label style={{ color: "#525252" }} htmlFor="other">
                                        Ownership Percentage
                                    </label>
                                    <input
                                        className="font_15 form-control input_field"
                                        type="text"
                                        id="other"
                                        value={percentage}
                                        onChange={(e) => setPercentage(e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-12   mt-4">
                                    <label style={{ color: "#525252" }}>Open Bankruptcies</label>
                                    <select
                                        className=" font_15 form-select Satoshi_fonts input_field font_15 form-control"
                                        aria-label="Default select example"
                                        style={{ fontWeight: 500 }}
                                        value={bankruptcies}
                                        onChange={(e) => setBankruptcies(e.target.value)}
                                    >
                                        <option selected="">Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12   mt-4">
                                    <label style={{ color: "#525252" }}>Open Judgements</label>
                                    <select
                                        className=" font_15 form-select Satoshi_fonts input_field font_15 form-control"
                                        aria-label="Default select example"
                                        style={{ fontWeight: 500 }}
                                        value={judgements}
                                        onChange={(e) => setJudgements(e.target.value)}
                                    >
                                        <option selected="">Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                {validationError &&
                                    <div className='text-center mt-3' style={{ color: 'red' }}>
                                        {validationError}
                                    </div>
                                }
                                <div className="text-center mt-4">

                                    <button className="btn-fill font_15 w-75 ms-1 next"
                                        type='submit'>
                                           {isLoading ? <ButtonLoader/>:'Continue'} </button>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/*footer*/}
                <footer className="container">
                    <p className="font_20 mt-lg-0  mt-4">
                        <i className="fa-solid fa-phone-volume me-2" />
                        Need Help?
                    </p>
                    <h3 className="font_22 font_700 font-DM-Sans">310-307-1916</h3>
                    <hr style={{ border: "1px solid #FFFFFF" }} />
                    <div className="text-center pb-2">
                        <p className="m-0">© Copyright 2023 | LOHNZ. All Rights Reserved.</p>
                    </div>
                </footer>
            </div>

        </>
    );
}

export default ApplicationStep1;
