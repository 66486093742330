import React from 'react';
import img1 from '../assets/pic7.svg'
import img2 from '../assets/pic8.svg'
const Step5 = () => {
  return (
  <>
  <div className="container">
  <div className="row flex-d-col" >
  <div className="col-lg-6 mt-lg-0 mt-4 col-12 text-center">
    <div className="row">
      <div className="col-lg-8 col-12 ">
        <div className="p-4 inneer-box">
          <img src={img1} alt="" />
          <h3 className="font_20 font_600 font-Bold-Ivy mt-4">
            Thanks for submitting your inquiry , Our team will soon reach out to
            you
          </h3>
          <button className='btn-fill mt-3'>
             Complete the Application Process
            </button>
        </div>
      </div>
    </div>
  </div>
  <div className="col-lg-6  col-12  d-flex justify-content-center align-items-center text-end position-relative z-3">
    <img src={img2} className="w-75" alt="" />
    
  </div>
</div>

  </div>
  </>
  );
}

export default Step5;
