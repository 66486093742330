import React from 'react';
import line from '../assets/img13.svg'
import Navbar from './Navbar';
import MultiStep from './MultiStep';

const HeroSection = () => {
    return (
        <>
            <div className="container-fluid bg-img position-relative ">
                <img
                    src={line}
                    className="position-absolute inner_imges"
                    alt=""
                />
                <Navbar />
                <div className="container pt-5 ">
                    <div className="row justify-content-center text-center">
                        <div className='col-lg-9 col-md-9 col-sm-12 col-12'>
                            <h3 className="font_32 font_700 font-Bold-Ivy position-relative z-3">
                                With Financial Constraints &amp; Rejections By Banks,{" "}
                                <span className="green_color"> LOHNZ Has You Covered!</span>
                            </h3>
                           
                        </div>
                        <div className='col-12 col-lg-6 col-md-6 col-sm-12'>
                        <p className="font_20 font-DM-Sans color_gray">
                                Apply today and have access to capital from $20,000 up to $20
                                million with in hour’s of being qualified.
                            </p>
                        </div>
                    </div>
                    <MultiStep />
                </div>
                <br />
                <br />
                <br />
                <br /> <br />
                <br />

            </div>

        </>
    );
}

export default HeroSection;
