import React, { useState } from 'react';

const Services = () => {
    const [account, setAccount] = useState(false);
    const [equipment, setEquipment] = useState(false);
    const [small, setSmall] = useState(false);
    const [business, setBusiness] = useState(false);
    const [term, setTerm] = useState(false);
    const [short, setShort] = useState(false);


    return (
        <>
                {/*section-Our Services*/}
                <div className="container-fluid position-relative bg-img">
                    <img
                        src="imges/pic21.svg"
                        className="position-absolute inner_imgees w-50"
                        alt=""
                    />
                    <div className="container pt-3">
                        <div className="row justify-content-center text-center pb-5">
                            <h3 className="font_42 font_700 font-Bold-Ivy">Our Services</h3>
                            <div className="col-lg-5 col-md-7 col-sm-11 col-12">
                                <div className="accordion" id="accordionExample">
                                    <div className={`accordion-item p-4 ${account ? 'bg-with-shadow' : 'bg-transparent'}`}>
                                        <h2 className="accordion-header font_24 font_600 font-Bold-Ivy">
                                            <div className="d-flex">
                                                <h3 className=" text-white mt-2">01</h3>
                                                <button
                                                    className="accordion-button collapsed bg-img2 bg-tranparent"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne"
                                                    aria-expanded={account ? 'true' : 'false'}
                                                    aria-controls="collapseOne"
                                                    onClick={() => setAccount(!account)}
                                                >
                                                    Accounts receivable loans
                                                </button>
                                            </div>
                                        </h2>
                                        <div
                                            id="collapseOne"
                                            className="accordion-collapse collapse "
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body bg-img2">
                                                Business funding in which companies sell their unpaid invoices
                                                to a factoring company at a discounted rate in exchange for
                                                immediate cash
                                            </div>

                                            <a href='#'>
                                                <button className="btn-fill font_15 w-25 ms-1 next">
                                                    Apply Now
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className={`accordion-item p-4 ${equipment ? 'bg-with-shadow' : 'bg-transparent'}`}>
                                        <h2 className="accordion-header font_24 font_600 font-Bold-Ivy">
                                            <div className="d-flex">
                                                <h3 className="text-white mt-2">03</h3>
                                                <button
                                                    className="accordion-button collapsed bg-img2 bg-tranparent"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseTwo"
                                                    aria-expanded={equipment ? 'true' : 'false'}
                                                    onClick={() => setEquipment(!equipment)}
                                                    aria-controls="collapseTwo"
                                                >
                                                    Equipment financing
                                                </button>
                                            </div>
                                        </h2>
                                        <div
                                            id="collapseTwo"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body bg-img2">
                                                Long-term financing solution for the purchase of equipment
                                                with fixed or variable rates. With flexible repayment terms,
                                                you can choose a schedule that works for you and your business
                                                without sacrificing near-term cash flow.
                                            </div>
                                            <a href='#'>
                                                <button className="btn-fill font_15 w-25 ms-1 next">
                                                    Apply Now
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className={`accordion-item p-4 ${small ? 'bg-with-shadow' : 'bg-transparent'}`}>
                                        <h2 className="accordion-header font_24 font_600 font-Bold-Ivy">
                                            <div className="d-flex">
                                                <h3 className=" text-white mt-2">05</h3>
                                                <button
                                                    className="accordion-button collapsed bg-img2 bg-tranparent"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapseThree"
                                                    aria-expanded={small ? 'true' : 'false'}
                                                    aria-controls="collapseThree"
                                                    onClick={() => setSmall(!small)}
                                                >
                                                    Small business association loans
                                                </button>
                                            </div>
                                        </h2>
                                        <div
                                            id="collapseThree"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body bg-img2">
                                                SBA offers business owners the opportunity to take advantage
                                                of favorable terms as part of the SBA program while removing
                                                some of the barriers to traditional financing options.
                                            </div>
                                            <a href='#'>
                                                <button className="btn-fill font_15 w-25 ms-1 next">
                                                    Apply Now
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-7 col-sm-11 col-12">
                                <div className="accordion " id="accordionEample">
                                    <div className={`accordion-item p-4 ${business ? 'bg-with-shadow' : 'bg-transparent'}`}>
                                        <h2 className="accordion-header font_24 font_600 font-Bold-Ivy">
                                            <div className="d-flex">
                                                <h3 className="text-white mt-2">02</h3>
                                                <button
                                                    className='accordion-button collapsed bg-img2 bg-transparent'
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapsefour"
                                                    aria-expanded={business ? 'true' : 'false'}
                                                    aria-controls="collapsefour"
                                                    onClick={() => setBusiness(!business)}
                                                >
                                                    Business line of credit
                                                </button>
                                            </div>
                                        </h2>
                                        <div
                                            id="collapsefour"
                                            className='accordion-collapse collapse'
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className='accordion-body bg-img2 '> {/* Added 'with-shadow' class when open */}
                                                A business line of credit gives you the flexibility to borrow money when you need it, you only pay interest on the funds you actually use, and your balance is available again as you pay it down.
                                            </div>
                                            <a href='#'>
                                                <button className="btn-fill font_15 w-25 ms-1 next">
                                                    Apply Now
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className={`accordion-item p-4 ${term ? 'bg-with-shadow' : 'bg-transparent'}`}>
                                        <h2 className="accordion-header font_24 font_600 font-Bold-Ivy">
                                            <div className="d-flex">
                                                <h3 className=" text-white mt-2">04</h3>
                                                <button
                                                    className="accordion-button collapsed bg-img2 bg-tranparent"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapsefiv"
                                                    onClick={() => setTerm(!term)}

                                                    aria-controls="collapsefiv"
                                                    aria-expanded={term ? 'true' : 'false'}

                                                >
                                                    Term Loan
                                                </button>
                                            </div>
                                        </h2>
                                        <div
                                            id="collapsefiv"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionEample"
                                        >
                                            <div className="accordion-body bg-img2">
                                                Borrowers receive the loan and agree to pay it back in
                                                regularly scheduled payments over a set period of time. In
                                                most cases, fixed monthly payments are made for the entire
                                                loan term. Term loans are typically repaid over a longer
                                                period of time than other types of loans.
                                            </div>
                                            <a href='#'>
                                                <button className="btn-fill font_15 w-25 ms-1 next">
                                                    Apply Now
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                    <div className={`accordion-item p-4 ${short ? 'bg-with-shadow' : 'bg-transparent'}`}>
                                        <h2 className="accordion-header font_24 font_600 font-Bold-Ivy">
                                            <div className="d-flex">
                                                <h3 className=" text-white mt-2">06</h3>
                                                <button
                                                    className="accordion-button collapsed bg-img2 bg-tranparent"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#collapsesix"
                                                    aria-expanded={short ? 'true' : 'false'}
                                                    onClick={() => setShort(!short)}
                                                    aria-controls="collapsesix"
                                                >
                                                    Short term loans
                                                </button>
                                            </div>
                                        </h2>
                                        <div
                                            id="collapsesix"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionEample"
                                        >
                                            <div className="accordion-body bg-img2">
                                                Access to a one-time lump sum of cash with a lower interest
                                                rate. You can get your funds as soon as the day your loan is
                                                approved.
                                            </div>
                                            <a href='#'>
                                                <button className="btn-fill font_15 w-25 ms-1 next">
                                                    Apply Now
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
        </>
    );
}

export default Services;
